import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Link } from 'gatsby';
//assets
import backIcon from '../../images/back.svg';

//header styles
import Colors from '../../constants/Colors';

const Container = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;
const HeaderBack = styled(Link)`
    display: flex;
    align-items: center;
    justify: center;
`;
const HeaderBackIcon = styled.img`
    height: 24px;
`;
const HeaderBackText = styled.p`
    margin: 0px;
    margin-left: 5px;
    margin-bottom: -4px;
    text-transform: uppercase;
    font-size: 14px;
`;
const HeaderButton = styled(Link)`
    background: ${Colors.PRIMARY};
    padding: 15px 20px 10px 20px;
    border-radius: 6.4px;
    display: flex;
    align-items: center;
    justify: center;
    @media (max-width: 800px) {
        display: none;
    }
`;
const HeaderButtonText = styled.p`
    color: white;
    margin: 0px;
    font-size: 14px;
    font-weight: 700;
`;
const LogoLink = styled(Link)`
`;
const Logo = styled.img`
    height: 48px;
    width: auto;
    @media (max-width: 800px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;
const Header = ({
    headerBackText,
    headerBackLink,
    headerButtonText,
    headerButtonLink,
    site
}) => {
    return (
        <Container>
            <HeaderBack data-testid='backLink' to={headerBackLink}>
                <HeaderBackIcon src={backIcon} />
                <HeaderBackText data-testid='backText'>{headerBackText}</HeaderBackText>
            </HeaderBack>
            <LogoLink to="/">
                {site.logo && <Logo data-testid='logo' src={site.logo} alt={site.title} />}
            </LogoLink>
            <HeaderButton data-testid='buttonLink' to={headerButtonLink}>
                <HeaderButtonText data-testid='buttonText' >{headerButtonText}</HeaderButtonText>
            </HeaderButton>
        </Container>
    )
}

Header.propTypes = {
    headerBackText: PropTypes.string,
    headerBackLink: PropTypes.string,
    headerButtonText: PropTypes.string,
    headerButtonLink: PropTypes.string,
    site: PropTypes.shape({
        logo: PropTypes.string,
        title: PropTypes.string
    })
}
export default Header;
