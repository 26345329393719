import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import colors from '../../constants/Colors';
/**
* Email Input layout component
*
* The Email Input is used to gather email from users
* It is the input used for all occurances of email input
* 
*/

const Container = styled.div`
    height: 64px;
    border-radius: 6.4px;
    background: white;
    border: ${props => props.isError ? '1.27946px solid red' : '1.27946px solid rgba(84, 34, 179, 0.5)'};
    padding: 5px 5px 5px 20px;
    min-width: 40%;
    display: flex;
    flex-direction: row;
`;
const Input = styled.input`
    height: 100%;
    border: none;
    flex-grow:2;
`;

const ConfirmedContainer = styled.div`
    background: ${colors.CONFIRM};
    padding: 25px;
    border-radius: 6.4px;
    display: flex;
`;

const ConfirmedMessage = styled.p`
    margin-bottom: 0px
`;
const ConfirmedEmail = styled.p`
    font-weight: 600;
    margin-left: 5px;
    margin-bottom: 0px;
`;




const EmailInput = ({
    isConfirmed,
    buttonText,
    setEmail,
    email,
    placeholder,
    confirmedMessage,
    isError
}) => {


    if (isConfirmed) {
        //confirm state
        return (
            <ConfirmedContainer data-testid='container'>
                <ConfirmedMessage data-testid='message'>
                    {confirmedMessage}
                </ConfirmedMessage>
                <ConfirmedEmail data-testid='email'>
                    {email}
                </ConfirmedEmail>
            </ConfirmedContainer>
        )
    }

    //default state
    return (
        <Container
            isError={isError}
            data-testid='container'
        >
            <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={placeholder}
                data-testid='input'
                type='email'
            />
            <Button
                buttonText={buttonText}
                data-testid='button'
                type='submit'
            />
        </Container>
    )
}
EmailInput.propTypes = {
    isConfirmed: PropTypes.bool,
    buttonText: PropTypes.string,
    setEmail: PropTypes.func,
    email: PropTypes.string,
    placeholder: PropTypes.string,
    confirmedMessage: PropTypes.string
}

export default EmailInput;