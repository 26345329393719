import React from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../constants/Colors';

const Container = styled.input`
    height: 100%;
    background: ${colors.PRIMARY};
    border-radius: 6.4px;
    color: white;
    font-weight: 600;
    border: none;
    padding: 15px 20px 10px 20px;
    white-space: nowrap;
   text-align: center;
`;
const Button = ({ buttonText, onClick }) => {
    return (
        <Container
            data-testid='button'
            type="submit"
            value={buttonText}
        />
    )
}


Button.propTypes = {
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
}

export default Button;