import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import rightIcon from '../../images/right.svg';
//styles
const Container = styled(Link)`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
    width: 100%;
    display: flex;
    margin-bottom: 96px;
    align-items: center;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;
const PostTextContainer = styled.div`
    height: 100%;
`;
const PostImage = styled.img`
    max-width: 40%;
    margin-right: 65px;
    object-fit: contain;
    width: auto;
    border-radius: 7px;
    height: 100%;
    @media (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;
const PostTitle = styled.h2`
    margin: 0 0 10px 0;
    padding: 0;
    color: black;
    font-size: 5.5rem;
    line-height: 110%;
    color: ${Colors.PARAGRAPH};
    font-weight: 800;
    letter-spacing: -0.2rem;
`;
const PostExcerpt = styled.section`
    font-weight: 400;
    margin-top: 10px;
    color: ${Colors.PARAGRAPH};
    opacity: 0.7;
    line-height: 150%;
    margin-bottom: 3rem;
    font-size: 2rem;
    letter-spacing: -0.05rem;
`;

const PostFeatured = styled.p`
    text-transform: uppercase;
    color: ${Colors.PARAGRAPH};
    opacity: 0.8;
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: 400;

`;

const PostDescription = styled.p`
    color: ${Colors.PARAGRAPH};
    opacity: 0.8;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.75;
    letter-spacing: -0.025rem;
`;

const PostButtonContainer = styled.div`
    display: flex;
`;
const PostButtonText = styled.p`
    margin-bottom: 0px;
    color: ${Colors.PRIMARY};
`;
const PostButtonIcon = styled.img`
    margin-left: 24px;
    margin-top: -5px;
    width: 16px;
`;


//helper functions
const getShortDescription = (post) => {
    //gets first 240 characters of post
    try {
        return `${post.plaintext.substring(0, 240)}...`;
    } catch (e) { 
        //if error just return the empty string
        return '';
    }
}

const FeaturedPostCard = ({ post }) => {
    const url = `/${post.slug}/`

    const shortDescription = getShortDescription(post);

    return (
        <Container to={url}>
            {post.feature_image &&
                <PostImage data-testid='image' src={post.feature_image} />
            }
            <PostTextContainer>
                <PostFeatured>Featured</PostFeatured>
                <PostTitle data-testid='title' >{post.title}</PostTitle>
                <PostExcerpt data-testid='excerpt' >{post.excerpt}</PostExcerpt>
                <PostDescription data-testid='desc'>{shortDescription}</PostDescription>
                <PostButtonContainer>
                    <PostButtonText>Read More</PostButtonText>
                    <PostButtonIcon src={rightIcon} />
                </PostButtonContainer>
            </PostTextContainer>
        </Container>
    )
}

FeaturedPostCard.defaultProps = {
    post: {
        slug: '',
        title: '',
        excerpt: '',
        primary_author: {
            name: ''
        }
    }
  };
  
FeaturedPostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        plaintext: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}
export { getShortDescription };
export default FeaturedPostCard;
