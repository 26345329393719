import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import {
    EmailInput,
    SocialBar,
    Header
} from '.';


//constants
import ExtraSocials from '../../constants/ExtraSocials';
import TextData from '../../constants/Copy';

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/



// Styles
import '../../styles/app.css'
import Colors from '../../constants/Colors';

//container styles
const Viewport = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding: 50px 150px 0px 150px;
    @media (max-width: 800px) {
        padding: 50px 20px 0px 20px;

    }
`;
const ViewportTop = styled.div``;
const ViewportBottom = styled.div``;


//footer
const Footer = styled.footer`
    background: ${Colors.LIGHT_BACKGROUND};
    padding: 80px 140px 100px 140px;
    position: absolute;
    left: 0px;
    right: 0px;
    @media (max-width: 800px) {
        padding: 80px 20px 100px 20px;
    }
`;
const EmailInputContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
`;
const EmailInputSubtext = styled.p`
    margin-top: 20px;
    font-size: 14px;
    color: ${Colors.LIGHT_PARAGRAPH}
`;
const EmailInputText = styled.p`
    font-weight: 600;
    margin-bottom: 20px;
    color: ${Colors.PARAGRAPH};
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    text-align: center;
`;
const FooterLogo = styled.img`
    height: 48px;
    width: auto;
    @media (max-width: 800px) {
        height: 24px;
    }
`;

const SocialContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;




//Component
const DefaultLayout = ({ 
    data, 
    children, 
    bodyClass, 
    headerBackText, 
    headerBackLink, 
    headerButtonText, 
    headerButtonLink,
    onFooterEmailChange,
    footerEmail,
    footerSubmit,
    footerEmailConfirmed,
    footerError
}) => {
    const site = data.allGhostSettings.edges[0].node;
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null;
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null;
    const linkedInUrl = ExtraSocials.linkedin ? `https://www.linkedin.com/${ExtraSocials.linkedin.replace(/^\//, ``)}` : null;
    const instagramUrl = ExtraSocials.instagram ? `https://www.instagram.com/${ExtraSocials.instagram.replace(/^\//, ``)}` : null;
    
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7661110.js"></script>

                <body className={bodyClass} />
            </Helmet>


            <Viewport>
                <ViewportTop>
                    {/* The main header section on top of the screen */}
                    <Header 
                        headerBackText={headerBackText}
                        headerBackLink={headerBackLink}
                        headerButtonText={headerButtonText}
                        headerButtonLink={headerButtonLink}
                        site={site}
                    />
                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </ViewportTop>

                <ViewportBottom>
                    {/* The footer at the very bottom of the screen */}
                    <Footer>
                        <EmailInputContainer
                            name="blog-footer"
                            onSubmit={(e) => footerSubmit(e, footerEmail)}
                        >
                            <EmailInputText>
                                {TextData.footerText}
                            </EmailInputText>
                            <EmailInput 
                                placeholder={TextData.footerEmailInputPlaceholder} 
                                buttonText={TextData.footerEmailInputButton}
                                setEmail={onFooterEmailChange}
                                email={footerEmail}
                                isConfirmed={footerEmailConfirmed}
                                isError={footerError}
                                confirmedMessage={TextData.footerConfirmed}
                            />
                            <EmailInputSubtext>
                                {TextData.footerEmailSubtext}
                            </EmailInputSubtext>
                        </EmailInputContainer>
                        <SocialContainer>
                            {site.logo && <FooterLogo src={site.logo} alt={site.title} />}
                            <SocialBar 
                                linkedInUrl={linkedInUrl}
                                twitterUrl={twitterUrl}
                                facebookUrl={facebookUrl}
                                instagramUrl={instagramUrl}
                            />
                        </SocialContainer>
                    </Footer>
                </ViewportBottom>
            </Viewport>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    headerBackText: PropTypes.string,
    headerBackLink: PropTypes.string,
    headerButtonText: PropTypes.string,
    headerButtonLink: PropTypes.string,
    onFooterEmailChange: PropTypes.func,
    footerSubmit: PropTypes.func,
    footerEmail: PropTypes.string,
    footerEmailConfirmed: PropTypes.bool,
    footerError: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

//Queries
const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "favicon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery;
