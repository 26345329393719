import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import {SocialBar} from '.';

//styles
const Container = styled.div`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
    width: 100%;
    display: flex;
    margin-bottom: 96px;
    align-items: center;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;
const AuthorTextContainer = styled.div`
    height: 100%;
`;
const AuthorImage = styled.img`
    max-width: 40%;
    margin-right: 65px;
    object-fit: contain;
    width: auto;
    border-radius: 7px;
    height: 100%;
    @media (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
`;
const AuthorTitle = styled.h2`
    margin: 0 0 10px 0;
    padding: 0;
    color: black;
    font-size: 42px;
    line-height: 110%;
    color: ${Colors.PARAGRAPH};
    font-weight: 800;
`;
const AuthorExcerpt = styled.section`
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
    color: ${Colors.PARAGRAPH};
    opacity: 0.8;
    line-height: 150%;
    margin-bottom: 20px;
    font-size: 24px;
`;

const AuthorDescription = styled.p`
    color: ${Colors.PARAGRAPH};
    opacity: 0.8;
    font-weight: 400;
    font-size: 21px;
`;


const AuthorCard = ({ author }) => {
    //social urls
    const twitterUrl = author.twitter ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = author.facebook ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}` : null

    return (
        <Container>
            {author.profile_image &&
                <AuthorImage data-testid='image' src={author.profile_image} />
            }
            <AuthorTextContainer>
                <AuthorTitle data-testid='name' >{author.name}</AuthorTitle>
                <AuthorExcerpt data-testid='location' >{author.location}</AuthorExcerpt>
                <AuthorDescription data-testid='bio'>{author.bio}</AuthorDescription>
                <SocialBar
                    twitterUrl={twitterUrl}
                    facebookUrl={facebookUrl}
                />
            </AuthorTextContainer>
        </Container>
    )
}

AuthorCard.defaultProps = {
    author: {
        slug: '',
        title: '',
        excerpt: '',
        primary_author: {
            name: ''
        }
    }
};

AuthorCard.propTypes = {
    author: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        plaintext: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}
export default AuthorCard;
