import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components';
import Colors from '../../constants/Colors';

//styles
const Container = styled(Link)`
    color: inherit;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;
const PostHeader = styled.header``;
const PostImage = styled.div`
    margin: 0;
    width: auto;
    height: 200px;
    background: var(--color-secondary) no-repeat center center;
    background-size: cover;
    border-radius: 7px;

`;
const PostTitle = styled.h2`
    margin: 0;
    padding: 0;
    color: black;
    font-size: 2.4rem;
    margin-top: 3rem;
    line-height: 125%;
    letter-spacing: -0.1rem;
`;
const PostExcerpt = styled.section`
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
    color: ${Colors.PARAGRAPH};
    opacity: 0.6;
    line-height: 1.75;
`;



const PostCard = ({ post }) => {
    
    const url = `/${post.slug}/`

    return (
        <Container to={url}>
            <PostHeader>
                {post.feature_image &&
                    <PostImage
                        data-testid='image'
                        style={{
                            backgroundImage: `url(${post.feature_image})`,
                        }}
                    ></PostImage>
                }
                <PostTitle
                    data-testid='title'
                >
                    {post.title}
                </PostTitle>
            </PostHeader>
            <PostExcerpt
                data-testid='excerpt'
            >
                {post.excerpt}
            </PostExcerpt>
        </Container>
    )
}

PostCard.defaultProps = {
    post: {
        slug: '',
        title: '',
        excerpt: '',
        primary_author: {
            name: ''
        }
    }
  };
PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
