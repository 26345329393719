import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import twitterIcon from '../../images/twitter.svg';
import facebookIcon from '../../images/facebook.svg';
import instagramIcon from '../../images/instagram.svg';
import linkedinIcon from '../../images/linkedin.svg';


//styles
const SocialLink = styled.a``;
const Container = styled.div``;
const SocialIcon = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 20px;
    @media (max-width: 800px) {
        width: 18px;
        height: 18px;
    }
`;


const SocialBar = ({ 
    twitterUrl,
    facebookUrl,
    instagramUrl,
    linkedInUrl
 }) => {
    return (
        <Container>
            {facebookUrl && <SocialLink
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-testid='facebook'
            >
                <SocialIcon
                    src={facebookIcon}
                    alt="Facebook"
                />
            </SocialLink>
            }
            {twitterUrl && <SocialLink
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-testid='twitter'
            >
                <SocialIcon
                    src={twitterIcon}
                    alt="Twitter"
                />
            </SocialLink>
            }
            {instagramUrl && <SocialLink
                href={instagramUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-testid='instagram'
            >
                <SocialIcon
                    src={instagramIcon}
                    alt="Instagram"
                />
            </SocialLink>
            }
            {linkedInUrl && <SocialLink
                href={linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-testid='linkedIn'
            >
                <SocialIcon
                    src={linkedinIcon}
                    alt="LinkedIn"
                />
            </SocialLink>
            }
        </Container>
    )
}

SocialBar.propTypes = {
    twitterUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
    linkedInUrl: PropTypes.string
}
export default SocialBar;
